export default {
  data: () => ({
    loading_overlay: false,
    valid: false,
    token: null,
    captchaToken: null,
    message_status: "",
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 10 || "Password must be at least 10 characters long",
      (v) => /\d/.test(v) || "Password must contain at least one number",
      (v) =>
        /[!@#$%^&*(),.?":{}|<>]/.test(v) ||
        "Password must contain at least one symbol",
      (v) =>
        /[a-z]/.test(v) ||
        "Password must contain at least one lowercase letter",
      (v) =>
        /[A-Z]/.test(v) ||
        "Password must contain at least one uppercase letter",
    ],
    show1: false,
    dialog: false,
    countDown: 30,
    showCountDown: false,
    isDisabled: false,
    message: "",
    message_hint:
      "Recovery link for your account has been sent. Please check your email.",
  }),
  computed: {
    enviroments() {
      return process.env.VUE_APP_API_URL;
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "Confirm Password is required",
        (v) => v === this.password || "Passwords must match",
      ];
    },
    isPasswordSimilar() {
      return this.password === this.confirmPassword;
    },
    disableSubmit() {
      if (
        this.password === null ||
        this.password === "" ||
        this.confirmPassword === null ||
        this.confirmPassword === "" ||
        this.isPasswordSimilar === false
      ) {
        return true;
      } else return false;
    },
  },
  watch: {
    valid(newValue) {
      console.log(newValue, "valid");
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.valid = false;
      console.log(this.valid, "mounted");
    } else {
      window.location.href = "/404";
    }
  },
  methods: {
    error() {
      console.log("There something wrong with the recaptcha");
    },
    onCaptchaExpired() {
      this.valid = false;
      this.$refs.recaptcha.reset();
    },
    verify(response) {
      if (response) {
        this.captchaToken = response;
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    verifyRecaptcha() {
      const data = {
        response: this.captchaToken,
      };

      fetch(process.env.VUE_APP_API_URL + `api/v1/id/recaptcha/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.error) {
            this.$swal("Opps", result.message, "error");
          } else {
            this.submitNewPassword();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitNewPassword() {
      this.loading_overlay = true;

      const data = {
        token: this.token,
        new_password: this.password,
      };
      fetch(process.env.VUE_APP_API_URL + `api/v1/id/account/set-password`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          this.loading_overlay = false;
          if (result.error) {
            this.onCaptchaExpired();
            this.password = "";
            this.confirmPassword = "";
            this.$swal({
              text: result.message,
              icon: "error",
              showConfirmButton: true,
              confirmButtonText: "OK",
              allowOutsideClick: false,
            });
          } else {
            this.$swal({
              text: "Password Berhasil Dibuat",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Login",
              confirmButtonColor: "#3085d6",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push("/auth/login");
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toLogin() {
      this.$router.push({ name: "Login" });
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
